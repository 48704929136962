import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import RollStyles from "./Roll.module.scss";
import arrowRight from "../img/arrow_right.svg";

export default class LatestRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className={RollStyles.wrapper}>
        <h1 className={RollStyles.h1}>WHAT'S NEW</h1>
        {posts &&
          posts.map(({ node: post }) => {
            return (
              <Link
                key={post.id}
                to={post.fields.slug}
                className={RollStyles.linkWrapper}
              >
                <div className={RollStyles.itemWrapper}>
                  {post.frontmatter.thumbnail && (
                    <img
                      src={post.frontmatter.thumbnail}
                    />
                  )}
                  <div className={RollStyles.rightSide}>
                    <h2 className={RollStyles.title}>{post.frontmatter.title}</h2>
                    <span className={RollStyles.date}>
                      {post.frontmatter.date}
                    </span>
                    <div className={RollStyles.checkItOutWrapper}>
                      <p className={RollStyles.checkItOut}>check it out</p>
                      <img src={arrowRight} alt="check it out" />
                    </div>
                  </div>
                </div>
              </Link>
            )
          })}
      </div>
    );
  }
}

LatestRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};
