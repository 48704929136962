import React from "react";
import Layout from "../components/Layout";
import LatestRoll from "../components/queries/LatestRoll";
import IndexPageStyles from "./IndexPage.module.scss";

export const IndexPageTemplate = () => (
  <div className={IndexPageStyles.wrapper}>
    <LatestRoll />
  </div>
);

IndexPageTemplate.propTypes = {};

const IndexPage = () => {
  return (
    <Layout>
      <IndexPageTemplate />
    </Layout>
  );
};

IndexPage.propTypes = {};

export default IndexPage;
