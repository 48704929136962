import React from "react";
import LatestRool from "../LatestRoll";
import { graphql, StaticQuery } from "gatsby";

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query LatestRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "DD MMMM YYYY")
                  thumbnail
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <LatestRool data={data} count={count} />}
    />
  );
};
